import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import Wizard from "../components/Wizard";
import { useonboardingStore } from "../store";
import { AnimatePresence, motion } from "framer-motion";
import { secondStep } from "../services/shared/framermotion.service";

export default function EndOnboarding() {
  const onboardingData = useonboardingStore((state) => state.onboardingData);
  const history = useHistory();
  const [isFinished, setIsFinished] = useState(false);
  const goToNextStep = async () => {
    setIsFinished(true);
    setTimeout(() => {
      if (onboardingData?.storeUrl) {
        window.location.href = `${onboardingData.storeUrl}/admin/`;
      }
    }, 1000);
  };

  const goBack = async () => {
    setIsFinished(true);
    setTimeout(() => {
      history.push("/metadata");
    }, 1000);
  };

  return (
    <>
      <div className="flow-container onboarding-container">
        <div className="container-fluid">
          <Row className="row no-gutters">
            <Col xs={5} lg={4} className="left-col">
              <Wizard step="3"></Wizard>
            </Col>

            <Col xs={7} lg={8} className="overflow-hidden">
              <AnimatePresence>
                {!isFinished && (
                  <motion.div
                    key="endonboarding"
                    {...secondStep}
                    className="page-container d-flex align-items-center"
                  >
                    <div className="welcome">
                      <p className="title-page color-primary">
                        ¡Listo! Se aplicaron todos los cambios en tu
                        configuración.
                      </p>
                      <p className="description">
                        Ya puedes comenzar a recibir tus pagos en línea a través
                        de Conekta y hacer crecer tu negocio. ¡Gracias por
                        elegirnos!
                      </p>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
              <div className="footer">
                <Button variant="link" className="float-left" onClick={goBack}>
                  Volver
                </Button>
                <Button
                  variant="primary"
                  className="float-right"
                  onClick={goToNextStep}
                >
                  Finalizar
                  <i className="fas fa-chevron-right"></i>
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
