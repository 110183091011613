import Swal from "sweetalert2";

const showError = async (title, message, timer) => {
  Swal.fire({
    toast: true,
    position: "top-end",
    timer: timer ? timer : 3000,
    title: title ? title : "Error",
    text: message ? message : "Ocurrio un error",
    icon: "error",
    allowOutsideClick: true,
    showConfirmButton: false,
  });
};

const showSuccess = async (title, message, timer) => {
  Swal.fire({
    toast: true,
    position: "top-end",
    timer: timer ? timer : 3000,
    title: title ? title : "",
    text: message ? message : "Ocurrio un error",
    icon: "success",
    allowOutsideClick: true,
    showConfirmButton: false,
  });
};

export { showError, showSuccess };
