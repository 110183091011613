import {
  post as restPost,
  get as restGet,
  put as restPut,
} from "./shared/rest.service";

const checkConektaIntegration = async (params) => {
  try {
    const endpoint = "/onboarding/conekta-integration";
    const result = await restPost(endpoint, params);
    if (result.status && result.status === 200) {
      return result.data;
    } else {
      throw result.response.data;
    }
  } catch (error) {
    return error;
  }
};

export { checkConektaIntegration };
