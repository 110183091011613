import logo1 from "../assets/img/conekta.svg";
import logo2 from "../assets/img/tiendanube.png";

export default function Wizard({ step }) {
  let stepParams = parseInt(step);
  return (
    <div className="wizard">
      <div className="wizard-header d-flex align-items-center">
        <div className="logos">
          <img src={logo1} className="img-fluid" />
        </div>
        <div className="logos">
          <img src={logo2} className="img-fluid" />
        </div>
      </div>
      <div className="wizard-flow">
        <div className="wizard-component">
          <ul id="progressbar" className="text-center">
            <li
              className={
                stepParams === 0 || stepParams > 0 ? ` active step0` : "step0"
              }
            ></li>
            <li
              className={
                stepParams === 1 || stepParams > 1 ? ` active step0` : "step0"
              }
            ></li>
            <li
              className={
                stepParams === 2 || stepParams > 2 ? ` active step0` : "step0"
              }
            ></li>
            <li className={stepParams === 3 ? ` active step0` : "step0"}></li>
          </ul>
          <h6 className="active">Bienvenido</h6>
          <h6>Autenticación</h6>
          <h6>Configura tus medios de pago</h6>
          <h6>¡Listo! Recibe pagos en línea</h6>
        </div>
      </div>
    </div>
  );
}
