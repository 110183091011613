import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Button, Form, Accordion, Card } from "react-bootstrap";
import { useonboardingStore } from "../store";
import Wizard from "../components/Wizard";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { showError, showSuccess } from "../services/shared/alert.service";
import {
  updateUserMetadata,
  createWebHookOrder,
  getStoreData,
} from "../services/metadata.service";
import { createPaymentProvider } from "../services/welcome.service";
import Loading from "../components/Loading";
import { motion, AnimatePresence } from "framer-motion";
import { firstStep, secondStep } from "../services/shared/framermotion.service";

export default function Metadata() {
  let [isLoading, setIsLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const onboardingData = useonboardingStore((state) => state.onboardingData);
  const addData = useonboardingStore((state) => state.addData);
  let [advanceSetting, setAdvanceSetting] = useState(true);
  let [debitCard, setDebitCard] = useState(true);
  let [creditCard, setCreditCard] = useState(true);
  let [oxxo, setOxxo] = useState(true);
  let [transfer, setTransfer] = useState(true);
  let [threeMonthInstallments, setThreeMonthInstallments] = useState(true);
  let [sixMonthInstallments, setSixMonthInstallments] = useState(true);
  let [nineMonthInstallments, setNineMonthInstallments] = useState(true);
  let [eighteenMonthInstallments, setEighteenMonthInstallments] =
    useState(true);
  let [twelveMonthInstallments, setTwelveMonthInstallments] = useState(true);
  let [updateMetadata, setupdateMetadata] = useState(true);
  let [user_id, setUserId] = useState("");
  let [threeMin, setTheeMin] = useState(300.0);
  let [sixMin, setSixMin] = useState(600.0);
  let [nineMin, setNineMin] = useState(900.0);
  let [twelveMin, setTwelveMin] = useState(1200.0);
  let [eighteenMin, setEighteenMin] = useState(1800.0);
  let urlSplited = "";
  const history = useHistory();

  useEffect(() => {
    if (onboardingData && !updateMetadata) {
      setInitialConfiguration();
    }
  }, [onboardingData]);

  useEffect(async () => {
    urlSplited = window.location.search.split("&");
    if (urlSplited.length > 0) {
      setupdateMetadata(await validateUpdateUser(urlSplited));
    }
  }, []);

  async function validateUpdateUser(urlParams) {
    // setIsLoading(true);
    let validationData = false;
    if (urlParams[1]) {
      const store = urlParams[1].split("=")[1];
      const result = await getStoreData(store);

      if (!result.error) {
        validationData = true;
        await setMetadata(result.user);
      } else {
        showError("Error", result.error);
      }
    }
    // setIsLoading(false);
    return validationData;
  }

  async function setMetadata(userData) {
    setThreeMonthInstallments(userData.threeMonthInstallment);
    setSixMonthInstallments(userData.sixMonthInstallment);
    setNineMonthInstallments(userData.nineMonthInstallment);
    setTwelveMonthInstallments(userData.twelveMonthInstallment);
    setEighteenMonthInstallments(userData.eighteenhInstallment);
    setDebitCard(userData.paymentMethods.debitCard);
    setCreditCard(userData.paymentMethods.creditCard);
    setOxxo(userData.paymentMethods.ticket);
    setTransfer(userData.paymentMethods.wireTransfer);
    setUserId(userData.id);
  }

  function setInitialConfiguration() {
    if (onboardingData.threeMonthInstallments) {
      setThreeMonthInstallments(onboardingData.threeMonthInstallments);
      setTheeMin(onboardingData.threeMin);
    }

    if (onboardingData.sixMonthInstallments) {
      setSixMonthInstallments(onboardingData.sixMonthInstallments);
      setSixMin(onboardingData.sixMin);
    }

    if (onboardingData.nineMonthInstallments) {
      setNineMonthInstallments(onboardingData.nineMonthInstallments);
      setNineMin(onboardingData.nineMin);
    }

    if (onboardingData.twelveMonthInstallments) {
      setTwelveMonthInstallments(onboardingData.twelveMonthInstallments);
      setTwelveMin(onboardingData.twelveMin);
    }

    if (onboardingData.eighteenMonthInstallments) {
      setEighteenMonthInstallments(onboardingData.eighteenMonthInstallments);
      setEighteenMin(onboardingData.eighteenMin);
    }

    if (onboardingData.debitCard) {
      setDebitCard(onboardingData.debitCard);
    }

    if (onboardingData.creditCard) {
      setCreditCard(onboardingData.creditCard);
    }

    if (onboardingData.transfer) {
      setTransfer(onboardingData.transfer);
    }

    if (onboardingData.oxxo) {
      setOxxo(onboardingData.oxxo);
    }
  }

  const goToNextStep = async () => {
    // history.push("/end-onboarding");
    if (debitCard || creditCard || oxxo || transfer) {
      setIsLoading(true);
      const paymentMethods = setPaymentMethods();

      const monthsInstallments = setMonthsInstallments();

      const userId = user_id ? user_id : onboardingData.userId;

      const result = await updateUserMetadata(
        paymentMethods,
        monthsInstallments,
        userId,
        updateMetadata
      );
      if (result) {
        if (!updateMetadata) {
          addData({
            ...onboardingData,
            threeMonthInstallments: threeMonthInstallments,
            threeMin: threeMin,
            sixMonthInstallments: sixMonthInstallments,
            sixMin: sixMin,
            nineMonthInstallments: nineMonthInstallments,
            nineMin: nineMin,
            twelveMonthInstallments: twelveMonthInstallments,
            twelveMin: twelveMin,
            eighteenMonthInstallments: eighteenMonthInstallments,
            eighteenMin: eighteenMin,
            debitCard: debitCard,
            creditCard: creditCard,
            transfer: transfer,
            oxxo: oxxo,
          });

          const createPaymentResult = await createPaymentProvider(
            onboardingData.userId
          );
          if (createPaymentResult) {
            const createWebHook = await createWebHookOrder(
              onboardingData.userId
            );
            if (!createWebHook.error) {
              setIsLoading(false);
              setIsFinished(true);
              setTimeout(() => {
                history.push("/end-onboarding");
              }, 1000);
            } else {
              setIsLoading(false);
              showError("Error", createWebHook.error);
            }
          }else{
            setIsLoading(false);
            showError("Error", 'No pudimos configurar tu cuenta, por favor, vuelva a intentarlo');
          }
        } else {
          setIsLoading(false);
          showSuccess(
            "Actualización Exitosa",
            "Hemos actualizado el usuario de manera correcta!"
          );
        }
      } else {
        setIsLoading(false);
        showError(
          "Error",
          "No pudimos actualizar la metadata con los métodos de pago, por favor, vuelva a intentarlo"
        );
      }
    } else {
      showError(
        "Error",
        "Por favor, debe configurar aunque sea un método de pago"
      );
    }
  };

  const goBack = async () => {
    setIsFinished(true);
    setTimeout(() => {
      history.push("/conekta-integration");
    }, 1000);
  };

  const changeAdvanceSettingStatus = async () => {
    setAdvanceSetting(!advanceSetting);
  };

  function setPaymentMethods() {
    let paymentMethods = [];

    if (debitCard) {
      paymentMethods.push("debit_card");
    }

    if (creditCard) {
      paymentMethods.push("credit_card");
    }

    if (oxxo) {
      paymentMethods.push("ticket");
    }

    if (transfer) {
      paymentMethods.push("wire_transfer");
    }
    return paymentMethods;
  }

  function setMonthsInstallments() {
    let monthsInstallments = [];

    if (threeMonthInstallments) {
      monthsInstallments.push({
        key: "threeMonthInstallment",
        isAvailable: true,
        value: threeMin,
      });
    }

    if (sixMonthInstallments) {
      monthsInstallments.push({
        key: "sixMonthInstallment",
        isAvailable: true,
        value: sixMin,
      });
    }

    if (nineMonthInstallments) {
      monthsInstallments.push({
        key: "nineMonthInstallment",
        isAvailable: true,
        value: nineMin,
      });
    }

    if (twelveMonthInstallments) {
      monthsInstallments.push({
        key: "twelveMonthInstallment",
        isAvailable: true,
        value: twelveMin,
      });
    }

    if (eighteenMonthInstallments) {
      monthsInstallments.push({
        key: "eighteenhInstallment",
        isAvailable: true,
        value: eighteenMin,
      });
    }

    return monthsInstallments;
  }

  const handleChange = async (toogle, value) => {
    switch (toogle) {
      case "threeMonthInstallments":
        setThreeMonthInstallments(value);
        break;
      case "sixMonthInstallments":
        setSixMonthInstallments(value);
        break;
      case "nineMonthInstallments":
        setNineMonthInstallments(value);
        break;
      case "eighteenMonthInstallments":
        setEighteenMonthInstallments(value);
        break;
      case "twelveMonthInstallments":
        setTwelveMonthInstallments(value);
        break;
      // case 'card':
      //   setDebitCard(!debitCard);
      //   setCreditCard(!creditCard);
      //   break;
      // case 'oxxo':
      //   setOxxo(value);
      //   break;
      // case 'transfer':
      //   setTransfer(value);
      // break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="flow-container onboarding-container">
        <div className="container-fluid">
          <Row className="row no-gutters">
            <Col xs={5} lg={4} className="left-col">
              <Wizard step="32"></Wizard>
            </Col>

            <Col xs={7} lg={8} className="overflow-hidden">
              {/* Page container */}
              <AnimatePresence>
                {!isFinished && (
                  <motion.div
                    key="metadata"
                    className="page-container d-flex"
                    {...secondStep}
                  >
                    <Row className="row flex-fill metadata-page">
                      <Col xs={12} className="form-right">
                        {/* Formulario */}
                        <Form>
                          {/* Medios de Pago */}
                          <Row>
                            <Col className="col-12">
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>
                                  Medios de pago aceptados
                                </Form.Label>
                                <Form.Text className="text-muted d-flex align-items-center">
                                  <div className="d-inline-flex">
                                    {/* <img src="Users/mcapote/Martin/Martin/Mensajeros%20urbanos/src/img/icon-info.png" /> */}
                                    <i className="fas fa-info-circle"></i>
                                  </div>
                                  <p>
                                    Activa o desactiva el método de pago que
                                    prefieras. Estos serán los métodos de pago
                                    que podrán elegir tus clientes.
                                  </p>
                                </Form.Text>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-6">
                              <div className="toogle d-flex justify-content-between align-items-center">
                                <p className="toogle-label">Tarjeta</p>
                                {/* swicth-button */}
                                <div className="switch-button">
                                  <BootstrapSwitchButton
                                    checked={debitCard}
                                    size="sm"
                                    onstyle="info"
                                    onChange={(checked) => {
                                      setCreditCard(checked);
                                      setDebitCard(checked);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="toogle d-flex justify-content-between align-items-center">
                                <p className="toogle-label">Conekta Efectivo</p>
                                {/* swicth-button */}
                                <div className="switch-button">
                                  <BootstrapSwitchButton
                                    checked={oxxo}
                                    size="sm"
                                    onstyle="info"
                                    onChange={(checked) => {
                                      setOxxo(checked);
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col className="col-6">
                              <div className="toogle d-flex justify-content-between align-items-center">
                                <p className="toogle-label">
                                  Transferencia bancaria
                                </p>
                                {/* swicth-button */}
                                <div className="switch-button">
                                  <BootstrapSwitchButton
                                    checked={transfer}
                                    size="sm"
                                    onstyle="info"
                                    onChange={(checked) => setTransfer(checked)}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <div className="accordion-container">
                            <Accordion defaultActiveKey="0">
                              <Card>
                                <Card.Header>
                                  <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="1"
                                    onClick={changeAdvanceSettingStatus}
                                  >
                                    Configuración avanzada
                                    <i
                                      className={
                                        !advanceSetting
                                          ? "fas fa-chevron-up"
                                          : "fas fa-chevron-down"
                                      }
                                    ></i>
                                  </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                  <Card.Body>
                                    {/* Cuotas Permitidas */}
                                    <Row>
                                      <Col className="col-12">
                                        <Form.Group controlId="formBasicEmail">
                                          <Form.Label>
                                            Meses Sin Intereses disponibles para
                                            tus clientes
                                          </Form.Label>
                                          <Form.Text className="text-muted d-flex align-items-center">
                                            <div className="d-inline-flex">
                                              {/* <img src="Users/mcapote/Martin/Martin/Mensajeros%20urbanos/src/img/icon-info.png" /> */}
                                              <i className="fas fa-info-circle"></i>
                                            </div>
                                            <p>
                                              Revisa las comisiones{" "}
                                              <a
                                                href="https://conekta.com/pricing"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                aquí
                                              </a>
                                              .
                                            </p>
                                          </Form.Text>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="col-6">
                                        <div className="toogle d-flex justify-content-between align-items-center">
                                          <p className="toogle-label">
                                            3 Meses Sin Intereses
                                          </p>

                                          {/* swicth-button */}
                                          <div className="switch-button">
                                            <BootstrapSwitchButton
                                              checked={threeMonthInstallments}
                                              size="sm"
                                              onstyle="info"
                                              onChange={() =>
                                                handleChange(
                                                  "threeMonthInstallments",
                                                  !threeMonthInstallments
                                                )
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="toogle d-flex justify-content-between align-items-center">
                                          <p className="toogle-label">
                                            6 Meses Sin Intereses
                                          </p>

                                          {/* swicth-button */}
                                          <div className="switch-button">
                                            <BootstrapSwitchButton
                                              checked={sixMonthInstallments}
                                              size="sm"
                                              onstyle="info"
                                              onChange={() =>
                                                handleChange(
                                                  "sixMonthInstallments",
                                                  !sixMonthInstallments
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col className="col-6">
                                        <div className="toogle d-flex justify-content-between align-items-center">
                                          <p className="toogle-label">
                                            12 Meses Sin Intereses
                                          </p>

                                          {/* swicth-button */}
                                          <div className="switch-button">
                                            <BootstrapSwitchButton
                                              checked={twelveMonthInstallments}
                                              size="sm"
                                              onstyle="info"
                                              onChange={() =>
                                                handleChange(
                                                  "twelveMonthInstallments",
                                                  !twelveMonthInstallments
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="d-flex flex-column ">
                                          <div className="toogle d-flex flex-row  justify-content-between align-items-center">
                                            <p className="toogle-label">
                                              18 Meses Sin Intereses
                                            </p>

                                            {/* swicth-button */}
                                            <div className="switch-button">
                                              <BootstrapSwitchButton
                                                checked={
                                                  eighteenMonthInstallments
                                                }
                                                size="sm"
                                                onstyle="info"
                                                onChange={() =>
                                                  handleChange(
                                                    "eighteenMonthInstallments",
                                                    !eighteenMonthInstallments
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>

                                          <div className="text-muted no-bg d-flex align-items-center position-absolute banamex-container">
                                            <div className="d-inline-flex">
                                              {/* <img src="Users/mcapote/Martin/Martin/Mensajeros%20urbanos/src/img/icon-info.png" /> */}
                                              <i className="fas fa-info-circle"></i>
                                            </div>
                                            <p
                                              className={`citibanamex-${eighteenMonthInstallments}`}
                                            >
                                              Solamente disponible para
                                              Citibanamex
                                            </p>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col className="col-6">
                                        <div className="toogle d-flex justify-content-between align-items-center">
                                          <p className="toogle-label">
                                            9 Meses Sin Intereses
                                          </p>

                                          {/* swicth-button */}
                                          <div className="switch-button">
                                            <BootstrapSwitchButton
                                              checked={nineMonthInstallments}
                                              size="sm"
                                              onstyle="info"
                                              onChange={() =>
                                                handleChange(
                                                  "nineMonthInstallments",
                                                  !nineMonthInstallments
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    {/* Valor mínimo del carrito */}
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </motion.div>
                )}
              </AnimatePresence>

              {/* Footer */}
              <div className="footer">
                {/* Back button */}
                <Button
                  variant="link"
                  className="float-left"
                  onClick={goBack}
                  disabled={isLoading}
                >
                  Volver
                </Button>

                {/* Next button */}
                {!isLoading ? (
                  <Button
                    variant="primary"
                    className="float-right"
                    onClick={goToNextStep}
                  >
                    Siguiente
                    <i className="fas fa-chevron-right"></i>
                  </Button>
                ) : (
                  <Loading text="Cargando..." classParams="float-right" />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
