import {
  post as restPost,
  get as restGet,
  put as restPut,
} from "./shared/rest.service";

const tnAuth = async (code) => {
  try {
    const endpoint = `/auth/tienda-auth?code=${code}`;
    const result = await restGet(endpoint);
    if (result.status && result.status === 200) {
      return result.data;
    } else {
      throw result.response.data;
    }
  } catch (error) {
    return error;
  }
};

const createPaymentProvider = async (userId) => {
  try {
    const endpoint = `/onboarding/install`;
    const body = {
      user_id: userId,
    };
    const result = await restPost(endpoint, body);
    if (result.status && result.status === 200) {
      return result.data;
    } else {
      throw result.response.data;
    }
  } catch (error) {
    return error;
  }
};

export { tnAuth, createPaymentProvider };
