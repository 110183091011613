import create from "zustand";
import { devtools, persist } from "zustand/middleware";

// ========= ONBOARDING =========

let onboarding = (set) => ({
  onboardingData: {},
  addData: (data) => set((state) => ({ onboardingData: data })),
  removeOnboardingData: () => set((state) => ({ onboardingData: {} })),
});

onboarding = persist(onboarding, { name: "onboardingData" });
onboarding = devtools(onboarding);

export const useonboardingStore = create(onboarding);
