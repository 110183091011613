import { post as restPost } from "./shared/rest.service";

const sendTransactionStatus = async (success, cartId) => {
  const body = { success: success == "true" ? true : false, cartId };
  try {
    //Notify Transaction to TN
    // const endpointNotify = "/conekta/pay/notifyTransaction";
    // const resultNotify = await restPost(endpointNotify, body);

    //Request redirect callback url
    const endpointCallback = "/conekta/pay/callback";
    const resultCallback = await restPost(endpointCallback, body);

    if (resultCallback.status && resultCallback.status === 200) {
      return resultCallback.data;
    }
  } catch (error) {}
};

export { sendTransactionStatus };
