import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Button, Form } from "react-bootstrap";
import Wizard from "../components/Wizard";
import { useonboardingStore } from "../store";
import { checkConektaIntegration } from "../services/conektaIntegration.service";
import { showError } from "../services/shared/alert.service";
import Loading from "../components/Loading";
import { secondStep } from "../services/shared/framermotion.service";
import { AnimatePresence, motion } from "framer-motion";
export default function ConektaIntegration() {
  let [isLoading, setLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  let [apiKey, setApiKey] = useState("");
  const history = useHistory();
  const onboardingData = useonboardingStore((state) => state.onboardingData);
  const addData = useonboardingStore((state) => state.addData);

  useEffect(() => {
    if (onboardingData) {
      if (onboardingData.apiKey) {
        setApiKey(onboardingData.apiKey);
      }
    }
  }, [onboardingData]);

  const goToNextStep = async () => {
    setLoading(true);
    const params = {
      user_id: onboardingData.userId,
      apiKey: apiKey,
    };

    if (apiKey) {
      const result = await checkConektaIntegration(params);
      if (result) {
        addData({
          ...onboardingData,
          apiKey: apiKey,
        });
        setLoading(false);
        setIsFinished(true);
        setTimeout(() => {
          history.push("/metadata");
        }, 1000);
      } else {
        setLoading(false);
        showError(
          "Error",
          "No pudimos integrarnos con Conekta, por favor, verifique los datos.",
        );
      }
    } else {
      setLoading(false);
      showError("Error", "Por favor, ingrese su API key.");
    }
  };

  const goBack = async () => {
    setIsFinished(true);
    setTimeout(() => {
      history.push("/");
    }, 1000);
  };

  const handleChange = async (e) => {
    switch (e.target.id) {
      case "apikey":
        setApiKey(e.target.value.replace(/\s+/g, ""));
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="flow-container onboarding-container">
        <div className="container-fluid">
          <Row className="row no-gutters">
            <Col xs={5} lg={4} className="left-col">
              <Wizard step="1"></Wizard>
            </Col>

            <Col xs={7} lg={8} className="conekta-integration overflow-hidden">
              {/* Page container */}
              <AnimatePresence>
                {!isFinished && (
                  <motion.div
                    key="conektaintegration"
                    {...secondStep}
                    className="page-container d-flex align-items-center"
                  >
                    <Row className="row align-items-center credential-form flex-fill">
                      <Col xs={12} className="">
                        <div className="title-page fw-bold my-5">
                          Ingresa tus llaves privadas de Conekta
                        </div>
                        <p className="description my-5">
                          Una vez concluído tu registro en Conekta, necesitas
                          obtener tus API keys de producción.
                        </p>
                        <p className="description my-5 secret-key-dialog">
                          Conoce como tener tus llaves secretas{" "}
                          <a
                            href="https://developers.conekta.com/docs/api-keys-producci%C3%B3n"
                            target="_blank"
                            rel="noreferrer"
                          >
                            haciendo clic aquí
                          </a>
                          , es importante tenerlas a la mano para continuar con
                          la instalación
                        </p>
                      </Col>
                      <Col xs={12} className="form-right">
                        <Form>
                          <Row>
                            <Col className="col-12">
                              <Form.Group controlId="formBasicApiKey">
                                <Form.Label>
                                  API Key Producción (Tokens/Llaves Privadas)
                                </Form.Label>
                                <i className="fas fa-lock  icon-input"></i>
                                <Form.Control
                                  type="password"
                                  id="apikey"
                                  onChange={handleChange}
                                  defaultValue={apiKey}
                                  placeholder="*****"
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </motion.div>
                )}
              </AnimatePresence>

              <div className="footer">
                {!isLoading ? (
                  <div className="d-flex flex-row justify-content-between ">
                    <Button
                      variant="link"
                      // disabled={!code}
                      onClick={goBack}
                    >
                      <i className="fas fa-chevron-left mr-2"></i>
                      Volver
                    </Button>
                    <Button
                      variant="primary"
                      // disabled={!code}
                      onClick={goToNextStep}
                    >
                      Siguiente
                      <i className="fas fa-chevron-right"></i>
                    </Button>
                  </div>
                ) : (
                  <Loading text="Cargando..." classParams="float-right" />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* <div>
        <h2>Conekta Integration</h2>
        <button className="btn btn-secondary" onClick={goBack}>Back</button>
            <button className="btn btn-primary" onClick={goToNextStep}>Next Step</button>
        </div> */}
    </>
  );
}
