import React from "react";
import { Spinner, Button } from "react-bootstrap";

export default function Loading({ text, classParams }) {
  return (
    <Button type="button" className="float-right btn btn-primary" disabled>
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />{" "}
      {text}
    </Button>
  );
}
