import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import Wizard from "../components/Wizard";
import { useonboardingStore } from "../store";
import { sendTransactionStatus } from "../services/transactionPending.service";
import { GiSandsOfTime } from "react-icons/gi";
export default function TransactionPending() {
  const onboardingData = useonboardingStore((state) => state.onboardingData);
  const history = useHistory();

  //Getting Query params
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const success = query.get("success");
  const cartId = query.get("orderId");

  const goToNextStep = async () => {
    window.location.href = `${onboardingData.storeUrl}/admin/`;
  };

  const goBack = async () => {
    history.push("/metadata");
  };

  //Sends request to back
  useEffect(() => {
    async function sendRequest() {
      const redirectLink = await sendTransactionStatus(success, cartId);
      if (redirectLink) {
        window.location.href = redirectLink;
      }
    }
    setTimeout(() => {
      sendRequest();
    }, 9000);
  }, []);

  return (
    <>
      <div id="transaction-pending" className="container">
        <div className="card">
          <div className="icon">
            <GiSandsOfTime />
          </div>
          <div className="title">Estamos procesando tu pago</div>
          <div className="subtitle">
            Por favor aguarde un instante, pronto será redirigido a la tienda
          </div>
        </div>
      </div>
    </>
  );
}
