import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Welcome from "../pages/Welcome";
import { AnimatePresence } from "framer-motion";
import ConektaIntegration from "../pages/ConektaIntegration";
import Metadata from "../pages/Metadata";
import EndOnboarding from "../pages/EndOnboarding";
import TransactionPending from "../pages/TransactionPending";
import "../App.scss";

require("dotenv").config();

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch key={1}>
        <AnimatePresence>
          <Route key="page-1" exact path="/" component={Welcome} />
          <Route
            key="page-2"
            exact
            path="/conekta-integration"
            component={ConektaIntegration}
          />
          <Route key="page-3" exact path="/metadata" component={Metadata} />
          <Route
            key="page-4"
            exact
            path="/end-onboarding"
            component={EndOnboarding}
          />
          <Route
            key="page-5"
            exact
            path="/transaction"
            component={TransactionPending}
          />
        </AnimatePresence>
      </Switch>
    </BrowserRouter>
  );
}
