import {
  post as restPost,
  get as restGet,
  put as restPut,
} from "./shared/rest.service";

const updateUserMetadata = async (
  paymentMethods,
  monthsInstallments,
  userId,
  updateUser,
) => {
  try {
    const params = {
      paymentMethods: paymentMethods,
      monthsInstallments: monthsInstallments,
      user_id: userId,
      updateUser: updateUser,
    };
    const endpoint = "/onboarding/updateUserMetada";
    const result = await restPost(endpoint, params);
    if (result.status && result.status === 200) {
      return result.data;
    } else {
      throw result.response.data;
    }
  } catch (error) {
    return error;
  }
};

const createWebHookOrder = async (userId) => {
  try {
    const params = {
      user_id: userId,
    };
    const endpoint = "/webhook/create-webhook";
    const result = await restPost(endpoint, params);

    if (result.status && result.status === 200) {
      return result.data;
    } else {
      return {
        error: "Error al crear el webhook",
      };
    }
  } catch (error) {
    return error;
  }
};

const getStoreData = async (storeId) => {
  try {
    const endpoint = `/onboarding/getStoreData?storeId=${storeId}`;
    const result = await restGet(endpoint);

    if (result.status && result.status == 200) {
      return result.data;
    } else {
      return {
        error: "No pudimos obtener la data del store",
      };
    }
  } catch (error) {
    return error;
  }
};

export { updateUserMetadata, createWebHookOrder, getStoreData };
