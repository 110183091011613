import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Routes from "./routes/Routes";
import TagManager from "react-gtm-module";

const idNum = process.env.REACT_APP_GTM_ID;
const tagManagerArgs = {
  gtmId: idNum,
};

TagManager.initialize(tagManagerArgs);
// debugger;
// const element = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>,
  document.getElementById("root"),
);
