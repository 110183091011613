import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

const post = async (endpoint, body, headers) => {
  try {
    const url = `${REACT_APP_BASE_URL}${endpoint}`;
    const result = await axios(url, {
      method: "POST",
      headers: headers ? headers : { "content-type": "application/json" },
      data: body,
    });
    return result;
  } catch (error) {
    return error;
  }
};

const get = async (endpoint, headers) => {
  try {
    const url = `${REACT_APP_BASE_URL}${endpoint}`;
    const result = await axios(url, {
      method: "GET",
      headers: headers ? headers : { "content-type": "application/json" },
    });
    return result;
  } catch (error) {
    return error;
  }
};

const put = async (endpoint, body, headers) => {
  try {
    const url = `${REACT_APP_BASE_URL}${endpoint}`;
    const result = await axios(url, {
      method: "PUT",
      headers: headers ? headers : { "content-type": "application/json" },
      data: body,
    });
    return result;
  } catch (error) {
    return error;
  }
};

export { post, put, get };
