const boilerplateFramermotion = {
  initial: "initial",
  animate: "animate",
  exit: "exit",
};

export const pageTransition = {
  ...boilerplateFramermotion,
  transition: {
    type: "spring",
    damping: 8,
    mass: 0.5,
    stiffness: 50,
  },
  initial: {
    opacity: 0,
    translateX: "-100%",
  },
  animate: {
    translateX: 0,
    opacity: 0,
  },
  join: {
    type: "spring",
    damping: 8,
    mass: 0.5,
    opacity: 1,
    translateX: 0,
    transition: {
      duration: 1,
    },
  },
  leave: {
    transition: {
      duration: 2,
      delay: 0.1,
    },
    opacity: 0,
    type: "spring",
    damping: 8,
    mass: 0.1,
    stiffness: 50,
  },
};

export const firstStep = {
  ...boilerplateFramermotion,
  transition: {
    type: "spring",
    damping: 8,
    mass: 0.5,
    stiffness: 50,
  },
  initial: {
    opacity: 0,
    translateX: "100%",
  },
  animate: {
    translateX: 0,
    opacity: 0,
  },
  join: {
    type: "spring",
    damping: 8,
    mass: 0.5,
    opacity: 1,
    translateX: 0,
    transition: {
      duration: 1,
    },
  },
  leave: {
    transition: {
      duration: 1,
      delay: 0.1,
    },
    opacity: 0,
    type: "spring",
    damping: 8,
    translateX: "-100%",
    mass: 0.1,
    stiffness: 50,
  },
};

export const secondStep = {
  ...boilerplateFramermotion,
  key: "metadata",
  transition: {
    type: "spring",
    damping: 8,
    mass: 0.5,
    stiffness: 50,
  },
  initial: {
    opacity: 0,
    translateX: "100%",
  },
  animate: {
    opacity: 1,
    translateX: 0,
  },
  exit: {
    translateX: "-100%",
    opacity: 0,
  },
};
